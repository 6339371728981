/* User dropdown menu */
.top-navbar__link-dropdown {
  display: table-cell;
  &.open {
    .top-navbar__link-dropdown-menu {
      display: block;
    }
  }

  &.active {
    border-bottom: 3px solid #0895df;
  }
}

.top-navbar__link-dropdown-menu {
  display: none;
  position: absolute;
  background: #313844;
  width: 250px;
  top: 92px;
  left: -10px;
  line-height: 1;
  border-radius: 5px;
  z-index: 1000;
  border: 1px solid #3a4250;
  &:before {
    position: absolute;
    top: -10px;
    left: 18px;
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #3a4250;
  }
}

.top-navbar__link-dropdown-link {
  padding: 0 5px;
  text-decoration: none;
  cursor: pointer;
  height: 77px;
  padding-top: 5px;
  border-bottom: 3px solid transparent;
  display: flex;
  align-items: center;
  color: #d1d5d6;
  &:hover {
    color: #0895df;
  }
}

.top-navbar__link-dropdown-icon {
  display: flex;
  align-items: center;
  margin-right: 5px;
  opacity: 0.6;
}

.top-navbar__link-dropdown-title {
  font-size: 1.2rem;
}

.top-navbar__link-dropdown__list {
  background: #313844;
  padding: 0;
  border-radius: 0 0 5px 5px;
  -webkit-border-radius: 0 0 5px 5px;
  -moz-border-radius: 0 0 5px 5px;
  list-style: none;
  padding: 1rem 0;
  margin: 0;
  > li {
    &.divider {
      border-bottom: 1px solid #3a4250;
      margin: 10px 0;
    }
  }
}

.top-navbar__link-dropdown__link {
  display: flex;
  padding: 10px;
  width: 100%;
  height: 100%;
  text-decoration: none;
  position: relative;
  color: #d1d5d6;
  outline: none !important;
  align-items: center;
  opacity: 0.8;
  &:hover,
  &.active {
    background: #3a4250;
    .top-navbar__link-dropdown__link-icon,
    .top-navbar__link-dropdown__link-text {
      color: #d1d5d6;
    }
  }
}

.top-navbar__link-dropdown__link-icon {
  color: #c1bfd1;
  display: table-cell;
  height: 100%;
  text-align: left;
  line-height: 0;
  padding-right: 4px;
}

.top-navbar__link-dropdown__link-text {
  display: table-cell;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  font-size: 1rem;
}
