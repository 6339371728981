.page-container {
  display: block;
  padding-right: 40px;
  padding-left: 40px;
  width: 100%;
  padding-top: 110px;
}

html,
body,
#root,
main {
  height: 100%;
}
