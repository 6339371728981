.btn-danger-custom {
  background-color: #b73737;
  border-color: #dc2626;

  &:hover,
  &:active {
    background-color: #9c2c2c;
    border-color: #b91c1c;
  }
}

.btn-pause {
  background-color: #b69739;
  border-color: #facc15;

  &:hover,
  &:active {
    background-color: #cf9d07;
    border-color: #eab308;
  }
}

.btn-primary {
  color: #fff;
  &:hover,
  &:active,
  &:disabled {
    color: #fff;
  }
}

.btn-lg {
  min-width: 100px;
}

.btn-rounded {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: $blue;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  transition: all 0.1s ease-in;
  &:hover {
    color: #fff;
    background: darken($blue, 10%);
  }

  &.btn-rounded-sm {
    width: 28px;
    height: 28px;
  }
  &.btn-rounded-xsm {
    width: 21px;
    height: 21px;
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
    &:hover {
      background: $blue;
    }
  }
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  opacity: 0.4;
}

.swal2-actions {
  & > button {
    min-width: 100px;
  }
}

.swal2-validation-message {
  font-weight: 500;
}
